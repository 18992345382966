import React, { useState, useRef } from 'react';
import Header from './components/structural/nav';
import FacebookOrganism from './components/posts/facebook/organism';
import TwitterOrganism from './components/posts/twitter/organism';
import WebsiteOrganism from './components/posts/website/organism'; // Import WebsiteOrganism
import GenerateMessageOrganism from './components/posts/generation/organism';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import KeolisLogo from './keolis-logo.png'; // Import the Keolis logo


function App() {
  const [messages, setMessages] = useState({
    twitter: '',
    facebook: '',
    website: '',
    file_urls: []
  });

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const facebookRef = useRef(null);
  const twitterRef = useRef(null);
  const websiteRef = useRef(null); // Create a reference for the WebsiteOrganism

  const handlePostAll = () => {
    console.log('Current messages:', messages);
    if (messages.facebook && messages.twitter) {
      setShowConfirmModal(true);
    }
  };

  const confirmPostAll = () => {
    if (facebookRef.current && twitterRef.current) {
        facebookRef.current.submit();
        twitterRef.current.submit();
    }
    if (websiteRef.current) websiteRef.current.submit(); // Call submit on the WebsiteOrganism
    setShowConfirmModal(false);
  };

  const cancelPostAll = () => {
    setShowConfirmModal(false);
  };

  const updateMessage = (type, value) => {
    setMessages(prevMessages => ({ ...prevMessages, [type]: value }));
  };

  return (
    <div>
      <Header />
      <div className="header-container">
        <img src={KeolisLogo} alt="Keolis Logo" className="keolis-logo" />
        <h1 className="display-5">
          <span className="artis">Artis</span>
          <span className="pulse">Pulse</span>
        </h1>
      </div>
      <div className="container"> 
        <div className="card">
          <GenerateMessageOrganism messages={messages} setMessages={setMessages}/>
        </div>
        <div className="card">
          <FacebookOrganism ref={facebookRef} message={messages.facebook} updateMessage={updateMessage} fileUrls={messages.file_urls}/>
        </div>
        <div className="card">
          <TwitterOrganism ref={twitterRef} message={messages.twitter} updateMessage={updateMessage} fileUrls={messages.file_urls}/>
        </div>
        <div className="card">
          <WebsiteOrganism ref={websiteRef} message={messages.website} updateMessage={updateMessage} />
        </div>
        <div className='card'>
          <Button onClick={handlePostAll} className="mb-3 tout-poster-btn post-btn" disabled={!messages.facebook || !messages.twitter}>Poster sur Facebook et Twitter</Button>
        </div>
      </div>

      <Modal show={showConfirmModal} onHide={cancelPostAll} centered>
          <Modal.Header closeButton>
              <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>Êtes-vous sûr de vouloir publier à la fois sur Facebook et Twitter?</Modal.Body>
          <Modal.Footer>
              <Button variant="secondary" onClick={cancelPostAll}>Non</Button>
              <Button variant="primary" onClick={confirmPostAll}>Oui</Button>
          </Modal.Footer>
      </Modal>
    </div>
  );
}

export default App;
