import React, { useState } from 'react';
import { generateMessage } from '../../../Api/axios';
import linesData from './lines_and_stops.json';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import { FaTimes } from 'react-icons/fa'; // Import the FaTimes icon
import { FaImage } from "react-icons/fa";

function GenerateMessageOrganism({ messages, setMessages }) {
  const [selectedLines, setSelectedLines] = useState([]);
  const [selectedStop, setSelectedStop] = useState('');
  const [stops, setStops] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDeviation, setSelectedDeviation] = useState('');
  const [selectedFiles, setSelectedFiles] = useState([]);

  const [inputs, setInputs] = useState({
    busLine: '',
    busStop: '',
    incidentMessage: ''
  });

  const handleLineChange = (selectedOptions) => {
    setSelectedLines(selectedOptions);

    if (selectedOptions.length === 1) {
      const line = linesData.find(l => l.line === selectedOptions[0].value);
      if (line) {
        setStops(line.stops);
      } else {
        setStops([]);
      }
    } else {
      setStops([]);
      setSelectedStop(''); // Clear stop selection when multiple lines are selected
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs(prev => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (e) => {
    const newFiles = Array.from(e.target.files).map(file => ({
      file,
      facebook: true,
      twitter: true
    }));
    setSelectedFiles([...selectedFiles, ...newFiles]);
  };

  const handleRemoveFile = (index) => {
    const updatedFiles = selectedFiles.filter((_, i) => i !== index);
    setSelectedFiles(updatedFiles);
    // Clear file input
    document.getElementById('files').value = '';
    // Update the messages state to reflect the changes in fileUrls
    setMessages(prevMessages => {
      const newFileUrls = prevMessages.file_urls.filter((_, i) => i !== index);
      return {
        ...prevMessages,
        file_urls: newFileUrls
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
  
    const formData = new FormData();
    selectedLines.forEach(line => formData.append('bus_lines', line.value));
    formData.append('bus_stop', selectedStop);
    formData.append('incident_message', inputs.incidentMessage);
    formData.append('deviation_type', selectedDeviation.value);
  
    selectedFiles.forEach(file => {
      formData.append('files', file.file);
    });
  
    generateMessage(formData).then(res => {
      if (res.data) {
        let data = res.data;
        setMessages(prevMessages => ({
          ...prevMessages,
          twitter: data.messages.twitter,
          facebook: data.messages.facebook,
          website: data.messages.website,
          file_urls: data.file_urls.map((url, i) => ({
            file_url: url,
            facebook: selectedFiles[i].facebook,
            twitter: selectedFiles[i].twitter
          }))
        }));
      }
      setIsLoading(false);
    }).catch(error => {
      console.error('Error:', error.message);
      setIsLoading(false); // Stop loading in case of error
    });
  };

  const deviationOptions = [
    { value: 'simple', label: 'Déviation Simple' },
    { value: 'inopinee', label: 'Déviation Inopinée' },
    { value: 'plan_neige', label: 'Plan Neige' }
  ];

  return (
    <div className="inner-container">
      <form onSubmit={handleSubmit} className="mb-3">
        <div className="form-group">
          <label htmlFor="busLine" className="form-label">Ligne de Bus:</label>
          <Select
            isMulti
            name="busLine"
            options={linesData.map(line => ({ value: line.line, label: line.line }))}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={handleLineChange}
            value={selectedLines}
          />
        </div><br />

        <div className="form-group">
          <label htmlFor="busStop" className="form-label">Arrêt de Bus:</label>
          <input
            required
            type="text"
            className="form-control"
            id="busStop"
            name="busStop"
            list="bus-stops"
            value={selectedStop}
            onChange={e => setSelectedStop(e.target.value)}
            placeholder="Arrêt"
            disabled={selectedLines.length !== 1}
          />
          <datalist id="bus-stops">
            {stops.map((stop, index) => (
              <option key={index} value={stop} />
            ))}
          </datalist>
        </div><br />

        <div className="form-group">
          <label htmlFor="deviationType" className="form-label">Type de Déviation:</label>
          <Select
            name="deviationType"
            options={deviationOptions}
            className="basic-single-select"
            classNamePrefix="select"
            onChange={setSelectedDeviation}
            value={selectedDeviation}
          />
        </div><br />

        <div className="form-group">
          <label htmlFor="incidentMessage" className="form-label">Message d'Incident:</label>
          <textarea
            className="form-control"
            id="incidentMessage"
            name="incidentMessage"
            required
            value={inputs.incidentMessage}
            onChange={handleChange}
          ></textarea>
        </div>

        <div className="form-group">
          <label htmlFor="files" className="form-label">Ajouter des fichiers:</label>
          <input
            type="file"
            className="form-control"
            id="files"
            name="files"
            multiple
            onChange={handleFileChange}
          />
          <div className="uploaded-files">
            {selectedFiles.map((file, index) => (
              <div key={index} className="file-item">
                <FaImage /> <p>&nbsp;</p>
                <span> {file.file.name}</span>
                <button type="button" onClick={() => handleRemoveFile(index)} className="remove-btn">
                  <FaTimes />
                </button>
              </div>
            ))}
          </div>
        </div><br />

        <button type="submit" className="mb-3 generate-btn" disabled={isLoading}>Générer Messages</button>
      </form>
      <Modal show={isLoading} centered>
        <Modal.Body>
          <div className="text-center">
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only"></span>
            </div>
            <p>Génération des messages en cours...</p>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default GenerateMessageOrganism;
