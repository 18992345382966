const domain = '127.0.0.1:8000/';
//pour le local
export const BOARD_SOCKET_API = 'ws://' + domain + 'ws/';

//pour le deploiment';
export const POSTS_API = 'https://api-artispulse.abalsia.com/posts/'; //quick and dirty

export const MESSAGE_GENERATION_API = POSTS_API + 'generate_messages/';

// API endpoint for posts on local server
//export const POSTS_API = 'http://' + domain + 'posts/';

// API endpoint for generating messages on local server
//export const MESSAGE_GENERATION_API = POSTS_API + 'generate_messages/';